<template>
  <pro-menu-layout>
    <div class="q-ma-md">
      <pro-deck
        :title="getRes(proDeckTitle)"
        :cards="items"
        :statuses="statuses"
        v-slot="{ card, status }"
        filter-by-status
      >
        <pro-deck-card
          v-bind="card"
          :status="status ? status.label : ''"
          :statusColor="status ? status.color : ''"
          :date="card.date"
          :expandable="!!card.technicalFolder.length"
          default-closed
        >
          <template>
            <Pro-folders
              :folders="card.technicalFolder"
              :show-download-button="true"
            />
          </template>
        </pro-deck-card>
      </pro-deck>
    </div>
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";
import ProFolders from "@/components/PROSmart/ProFolders";

export default {
  components: { ProFolders, ProMenuLayout, ProDeckCard, ProDeck },
  name: "ViewInterest",
  data() {
    return {
      items: [],
      proDeckTitle: "CustMenu.Com.Stepper.InterestResponses",
      statuses: [
        {
          key: "Interest",
          label: this.getRes("Document.Status.Interest"),
          color: "green",
        },
        {
          key: "NotInterest",
          label: this.getRes("Document.Status.NotInterest"),
          color: "orange",
        },
        {
          key: "NoResponse",
          label: this.getRes("Document.Status.NoResponse"),
          color: "purple",
        },
      ],
    };
  },
  created() {
    this.tenderId = parseInt(this.$route.params.id);
    this.$proSmart.documentUi.getInfo(this, this.tenderId).then((formData) => {
      if (formData.moduleName === "EOI") {
        this.proDeckTitle = "CustMenu.Com.Stepper.InterestResponsesEOI";
      } else if (formData.moduleName === "PQ") {
        this.proDeckTitle = "CustMenu.Com.Stepper.InterestResponsesPQ";
      }
    });
  },
  async mounted() {
    let lists = await this.$proSmart.documentUi.getInterestList(
      this,
      this.$route.params.id
    );
    console.log(this);

    this.items = lists.reduce(
      (
        tenderers,
        {
          name: title,
          responseType: status,
          submissionDate: date,
          processId: processId,
          ...rest
        }
      ) => {
        if (date) date = new Date(date);
        let actions;
        if (processId) {
          actions = [{ processId: processId, viewForm: true }];
        }
        return [...tenderers, { title, status, date, actions, ...rest }];
      },
      []
    );
  },
};
</script>
